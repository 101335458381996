import { configureStore } from "@reduxjs/toolkit";
import {
  apiStateSlice,
  bridgeSlice,
  commonSlice,
  statusSlice,
  stepSlice,
  walletSlice,
  widgetSlice, 
} from "./actions";

const store = configureStore({
  reducer: {
    bridge: bridgeSlice.reducer,
    steps: stepSlice.reducer,
    status: statusSlice.reducer,
    wallet: walletSlice.reducer,
    apiState: apiStateSlice.reducer,
    common: commonSlice.reducer,
    widget: widgetSlice.reducer,
  },
});

export default store;
