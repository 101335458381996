import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  txs: {
    loading: false,
    totalCount: 0,
    data: [],
  },
};

const apiStateSlice = createSlice({
  name: "apiState",
  initialState: initialData,
  reducers: {
    getTxs: (state, { payload }) => {
      return {
        ...state,
        txs: {
          ...state.txs,
          data: payload.data,
          totalCount: payload.totalCount,
        },
      };
    },
  },
});

export const apiStateActions = apiStateSlice.actions;

export default apiStateSlice;
