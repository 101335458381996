import {
  Alert,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import {
  CheckCircle,
  FileCopyOutlined,
  RemoveCircle,
  Settings,
  Warning,
} from "@mui/icons-material";
import { useState } from "react";

import { ChainNames } from "@glitter-finance/sdk-web";
import algoLogoDark from "../assets/images/algo.png";
import solanaLogo from "../assets/images/solana.svg";
import xAlgoLogo from "../assets/images/xalgo.png";
import xSolLogo from "../assets/images/xsol.png";
import { toLowerCase } from "./utils";

const TRON = "tron";
export const EXPLORER_STATUS = [
  "operational",
  "degraded",
  "downtime",
  "maintenance",
];

const Others = () => {
  const theme = useTheme();
  const { classes, cx } = useStyles();

  const AlertBar = ({ message }) => {
    return (
      <Alert
        variant="outlined"
        severity="error"
        className={cx(classes.paperBgLight, classes.myAlert)}
        style={{
          color: "#fff",
        }}
      >
        {message}
      </Alert>
    );
  };

  const ChainLogoList = () => {
    const chainLogoList = [
      {
        key: "ALGO",
        icon: algoLogoDark,
      },
      {
        key: "xSOL",
        icon: xSolLogo,
      },
      {
        key: "SOL",
        icon: solanaLogo,
      },
      {
        key: "xALGO",
        icon: xAlgoLogo,
      },
    ];
    return chainLogoList;
  };

  const CopyToClipboard = ({ text }) => {
    const [isCopied, setIsCopied] = useState(false);
    return isCopied ? (
      <Typography
        component="span"
        variant="p"
        className={classes.tileTitle}
        style={{ color: "#fff", padding: "0px 10px" }}
      >
        Copied
      </Typography>
    ) : (
      <Tooltip title="Copy address">
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(text);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
          }}
          style={{
            padding: "0px 10px",
          }}
          edge="end"
        >
          <FileCopyOutlined
            style={{
              color: "#ffffff",
              fontSize: "16px",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const checkColor = (status) => {
    switch (status) {
      case EXPLORER_STATUS[0]:
        return {
          msgHeader: "All services online",
          msg: "All services online",
          color: theme.palette.success.main,
          icon: (
            <Tooltip title="Operational" placement="top">
              <CheckCircle
                className={classes.statusIcon}
                style={{ color: theme.palette.success.main }}
              />
            </Tooltip>
          ),
        };
      case EXPLORER_STATUS[1]:
        return {
          msgHeader:
            "Some services are currently degraded. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
          msg: (
            <span>
              <span>Some services are currently degraded. </span>
              <br />
              <span style={{ fontSize: "0.6rem", lineHeight: "1.0" }}>
                We are working on restoring service as soon as possible. Please
                check out our twitter for any service notifications:
                https://twitter.com/GlitterFinance
              </span>
            </span>
          ),
          color: "#f49342",
          icon: (
            <Tooltip title="Degraded" placement="top">
              <Warning
                className={classes.statusIcon}
                style={{ color: "#f49342" }}
              />
            </Tooltip>
          ),
        };
      case EXPLORER_STATUS[2]:
        return {
          msgHeader:
            "Some services are currently unavailable. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
          msg: (
            <span>
              <span>Some services are currently unavailable. </span>
              <br />
              <span style={{ fontSize: "0.6rem" }}>
                We are working on restoring service as soon as possible. Please
                check out our twitter for any service notifications:
                https://twitter.com/GlitterFinance
              </span>
            </span>
          ),
          color: theme.palette.error.main,
          icon: (
            <Tooltip title="Downtime" placement="top">
              <RemoveCircle
                className={classes.statusIcon}
                style={{ color: theme.palette.error.main }}
              />
            </Tooltip>
          ),
        };
      case EXPLORER_STATUS[3]:
        return {
          msgHeader: "Some services are currently under maintenance",
          msg: "Some services are currently under maintenance",
          color: "#5F59F7",
          icon: (
            <Tooltip title="Maintenance" placement="top">
              <Settings
                className={classes.statusIcon}
                style={{ color: "#5F59F7" }}
              />
            </Tooltip>
          ),
        };
      default:
        return {
          msgHeader: "All services are online",
          msg: "All services are online",
          color: "#fff",
          icon: (
            <Tooltip title="Operational" placement="top">
              <CheckCircle
                className={classes.statusIcons}
                style={{ color: theme.palette.success.main }}
              />
            </Tooltip>
          ),
        };
    }
  };

  const formatTokenName = (tokenName) => {
    let _tokenName = tokenName?.toLowerCase();
    switch (_tokenName) {
      case "usdc":
        return "USDC";
      case "algo":
        return "ALGO";
      case "xalgo":
        return "xALGO";
      case "sol":
        return "SOL";
      case "xsol":
        return "xSOL";
      case "euroc":
        return "EUROC";
      default:
        return tokenName;
    }
  };

  return {
    AlertBar,
    ChainLogoList,
    algorandTxUrl,
    solanaTxnSignatureUrl,
    ethereumTxnUrl,
    avalancheTxnUrl,
    getTxUrlByChain,
    CopyToClipboard,
    checkColor,
    formatTokenName,
  };
};

export default Others;

const useStyles = makeStyles()((theme) => ({
  myAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
    minHeight: "45px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRadius: "13px",
    borderColor: "transparent",
    margin: "20px 0px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  paperBgDark: {
    background:
      "linear-gradient(40deg, rgb(255 252 252 / 13%), rgb(0 0 0 / 0%)) !important",
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
  },
  paperBgLight: {
    background: "rgba(255, 255, 255, 0.3) !important",
    boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
    "& .MuiAlert-icon": {
      color: "#000000",
    },
  },

  tileTitle: {
    color: "rgb(118, 208, 213)",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Barlow",
    fontWeight: 600,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      lineHeight: "10px",
    },
  },
  statusIcon: {
    fontSize: "4.5rem",
  },
}));

export function getBridgeType(token) {
  if (["sol", "algo", "xalgo", "xsol"].includes(toLowerCase(token))) {
    return "TokenV1";
  }

  if (["nosec", "xnosec"].includes(toLowerCase(token))) {
    return "TokenV2";
  }

  return "Circle";
}

function algorandTxUrl(network, algorandTxId) {
  return `https://${
    network === "testnet" ? "testnet." : ""
  }algoexplorer.io/tx/${algorandTxId}`;
}

function solanaTxnSignatureUrl(network, solanaTxId) {
  return `https://solscan.io/tx/${solanaTxId}?${
    network === "mainnet" ? "" : "cluster=testnet"
  }`;
}

function ethereumTxnUrl(network, ethereumTxId) {
  return `https://${
    network === "mainnet" ? "etherscan.io" : "goerli.etherscan.io/"
  }/tx/${ethereumTxId}`;
}

function arbitrumTxnUrl(network, arbitrumTxId) {
  return `https://${
    network === "mainnet" ? "arbiscan.io" : "goerli.arbiscan.io"
  }/tx/${arbitrumTxId}`;
}

function avalancheTxnUrl(network, avalancheTxId) {
  return `https://${
    network === "testnet" ? "testnet." : ""
  }snowtrace.io/tx/${avalancheTxId}`;
}

function tronTxnUrl(network, tronTxId) {
  return `https://${
    network === "testnet" ? "shasta." : ""
  }tronscan.org/#/transaction/${tronTxId}`;
}

function polygonTxnUrl(network, polygonTxId) {
  return `https://${
    network === "testnet" ? "mumbai." : ""
  }polygonscan.com/tx/${polygonTxId}`;
}

function bscTxnUrl(network, bscTxId) {
  return `https://${
    network === "testnet" ? "bscscan.com" : "bscscan.com"
  }/tx/${bscTxId}`;
}

function zkEvmTxnUrl(network, txId) {
  return `https://${
    network === "testnet" ? "zkevm.polygonscan.com" : "zkevm.polygonscan.com"
  }/tx/${txId}`;
}

function optimismTxnUrl(network, txId) {
  return `https://${
    network === "testnet"
      ? "optimistic.etherscan.io"
      : "optimistic.etherscan.io"
  }/tx/${txId}`;
}

export function getTxUrlByChain(chain, network, txId) {
  switch (chain) {
    case toLowerCase(ChainNames.ALGORAND):
      return algorandTxUrl(network, txId);
    case toLowerCase(ChainNames.SOLANA):
      return solanaTxnSignatureUrl(network, txId);
    case toLowerCase(ChainNames.ETHEREUM):
      return ethereumTxnUrl(network, txId);
    case toLowerCase(ChainNames.AVALANCHE):
      return avalancheTxnUrl(network, txId);
    case toLowerCase(ChainNames.POLYGON):
      return polygonTxnUrl(network, txId);
    case toLowerCase(ChainNames.ARBITRUM):
      return arbitrumTxnUrl(network, txId);
    case toLowerCase(ChainNames.BSC):
      return bscTxnUrl(network, txId);
    case toLowerCase(ChainNames.ZKEVM):
      return zkEvmTxnUrl(network, txId);
    case toLowerCase(ChainNames.OPTIMISM):
      return optimismTxnUrl(network, txId);
    case TRON:
      return tronTxnUrl(network, txId);
    default:
      return "";
  }
}

export function getOverriddenMinBridgeTokens({
  recommendedMinBridgeTokens,
  from,
  token,
}) {
  const tokenMap = {
    "algo:algorand": 5,
    "algo:solana": 5,
    "sol:algorand": 0.05,
    "sol:solana": 0.05,
  }[`${token.toLowerCase()}:${from.toLowerCase()}`];

  return tokenMap || recommendedMinBridgeTokens;
}