import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  amount: "",
  step: 1,
  isPendingWalletConnection: false,
  isPendingBridgeModal: false,
  isPendingSignWallet: false,
  isSuccessBridgeModal: false,
  lastTxHash: "",
  isWalletError: null,
  isIntegrationsEnabled: true,
};

const widgetSlice = createSlice({
  name: "widget",
  initialState: initialData,
  reducers: {
    setAmount: (state, action) => {
      const amount = action.payload;
      return { ...state, amount };
    },
    setStep: (state, action) => {
      const step = action.payload;
      return { ...state, step };
    },
    setModalState: (state, action) => {
      const isPendingWalletConnection = action.payload;
      return { ...state, isPendingWalletConnection };
    },
    setSignWalletState: (state, action) => {
      const isPendingSignWallet = action.payload;
      return { ...state, isPendingSignWallet };
    },
    setSuccessBridgeModalState: (state, action) => {
      const isSuccessBridgeModal = action.payload;
      return { ...state, isSuccessBridgeModal };
    },
    setPendingBridgeModalState: (state, action) => {
      const isPendingBridgeModal = action.payload;
      return { ...state, isPendingBridgeModal };
    },
    setLastTxHash: (state, action) => {
      const lastTxHash = action.payload;
      return { ...state, lastTxHash };
    },
    setWalletErrorState: (state, action) => {
      const isWalletError = action.payload;
      return { ...state, isWalletError };
    },
    setIntegrationFeatureState: (state, action) => {
      const isIntegrationsEnabled = Boolean(action.payload);
      return { ...state, isIntegrationsEnabled };
    },
  },
});

export const widgetActions = widgetSlice.actions;
export default widgetSlice;
