import React, { createContext, useState } from "react";

const WalletContext = createContext(null);

export const WalletContextProvider = ({ children }) => {
  const [sourceWalletProvider, setSourceWalletProvider] = useState(null);
  const [destinationWalletProvider, setDestinationWalletProvider] =
    useState(null);

  const setSourceProvider = (walletprovider) => {
    setSourceWalletProvider(walletprovider);
  };

  const setDestinationProvider = (walletprovider) => {
    setDestinationWalletProvider(walletprovider);
  };

  const value = {
    sourceWalletProvider,
    destinationWalletProvider,
    setSourceProvider,
    setDestinationProvider,
  };

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
};

export default WalletContext;
