import { createSlice, current } from "@reduxjs/toolkit";
import { steps } from "../../utils/dataConfig";

const initialData = { journey: [steps.SRC_CHAIN], activestep: steps.SRC_CHAIN };

const stepSlice = createSlice({
  name: "steps",
  initialState: initialData,
  reducers: {
    processStep: (state, action) => {
      const { journey } = current(state);
      const step = action.payload;
      if (journey.includes(step)) {
        const slizeIndex = journey.indexOf(step);
        const newJourney = journey.slice(0, slizeIndex + 1);
        const activestep = newJourney[newJourney.length - 1];
        return { activestep, journey: newJourney };
      }
      const test = { activestep: step, journey: [...journey, step] };
      return { ...test };
    },
    resetSteps: () => {
      return { ...initialData };
    },
    setStepsOnExchange: () => {
      const journey = [
        steps.SRC_CHAIN,
        steps.SRC_TOKEN,
        steps.DEST_CHAIN,
        steps.DEST_TOKEN,
        steps.AMT_SWAP,
      ];
      const activestep = steps.AMT_SWAP;
      return { journey, activestep };
    },
  },
});

export const stepActions = stepSlice.actions;

export default stepSlice;
