import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as BridgingCompleted } from "../../assets/anims/bridgeProcessStatus.svg";
import { ReactComponent as BridgingProgress } from "../../assets/anims/bridgingProgress.svg";
import { ReactComponent as Completed } from "../../assets/anims/completed.svg";
import { ReactComponent as SignTx } from "../../assets/anims/signTx.svg";
import LoaderIcon from "../../assets/icons/spinner_icon.svg";
import useScreenSize from "../../hooks/useScreenSize";
import Others from "../../utils/chainHelpers";
import { EVM_SUPPORTED_CHAINS } from "../../utils/dataConfig";
import css from "./../base/css/ExcangeItems.module.css";
import AppButton from "./AppButton";
import useStyles from "./css/StatusNotifiersStyles";

export const Spinner = ({ height = 200, width = 200 }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.flexDisplay}>
      <img src={LoaderIcon} alt="Loading..." height={height} width={width} />
    </Box>
  );
};

const DialogWrapper = ({ isDialogOpen, closeDialogHandler, children }) => {
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);
  const { classes } = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      {closeDialogHandler && (
        <DialogTitle sx={{ padding: isSmallDevice ? 0 : null }}>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={closeDialogHandler}
            className={classes.iconBtnRoot}
          >
            <Close style={{ color: "#ffffff" }} />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export const SignTxDialog = ({ isDialogOpen, closeDialogHandler }) => {
  return (
    <DialogWrapper
      isDialogOpen={isDialogOpen}
      closeDialogHandler={closeDialogHandler}
    >
      <Box sx={{ textAlign: "center" }}>
        <SignTx />
        <br />
        <Typography
          variant="body1"
          sx={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          Please approve the transaction
        </Typography>
      </Box>
    </DialogWrapper>
  );
};

export const USDCBridgingDialog = ({
  isDialogOpen,
  isBridgeLoading,
  bridgeError,
  closeDialogHandler,
  fromChain,
  toChain,
  txHash,
}) => {
  const { classes, cx } = useStyles();
  const { getTxUrlByChain } = Others();
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);
  const toChainIcon = require(`../../assets/icons/${toChain}_icon.png`);

  return (
    <DialogWrapper
      isDialogOpen={isDialogOpen}
      closeDialogHandler={closeDialogHandler}
    >
      {isBridgeLoading && !bridgeError ? (
        <div className={classes.waitModal}>
          <CircularProgress
            size={122}
            thickness={4}
            sx={{
              color: true ? "#F66FC0" : "#000000",
              marginBottom: "0px",
            }}
          />
          <br />
          <Typography
            variant="h2"
            className={classes.waitModalText}
            sx={{
              marginTop: "0px !important",
              fontSize: isSmallDevice
                ? "1.0rem !important"
                : "1.7rem !important",
            }}
          >
            PLEASE WAIT
          </Typography>
          <Typography
            variant="h2"
            className={classes.waitModalText}
            sx={{
              marginTop: "0px !important",
              fontSize: isSmallDevice
                ? "1.0rem !important"
                : "1.7rem !important",
            }}
          >
            While we are swapping...
          </Typography>
        </div>
      ) : bridgeError ? (
        <div className={classes.waitModal}>
          <Close className={classes.closeIcon} />
          <br />
          <Typography
            variant="h2"
            className={classes.waitModalText}
            sx={{
              fontSize: isSmallDevice
                ? "1.0rem !important"
                : "1.7rem !important",
            }}
          >
            TRANSACTION WAS NOT CONFIRMED
          </Typography>

          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: isSmallDevice
                ? "1.0rem !important"
                : "1.7rem !important",
              marginTop: "0px !important",
              textTransform: "uppercase",
            }}
          >
            Please refresh the page and try again
          </Typography>
          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: isSmallDevice
                ? "0.5rem !important"
                : "0.0rem !important",
            }}
          >
            Contact support at: &nbsp;
            <a
              href="mailto: support@glitter.finance"
              className={classes.linkTeam}
            >
              support@glitter.finance
            </a>
          </Typography>
        </div>
      ) : (
        <>
          <div className={classes.waitModal}>
            <img src={toChainIcon} alt={toChain} className={classes.imgLogo} />
          </div>
          <div className={classes.waitModal}>
            {txHash ? (
              <Completed className={classes.swapIcon} />
            ) : (
              <Close className={classes.swapIcon} />
            )}
          </div>

          <Typography variant="h5" className={classes.waitModalText}>
            <div>
              <Typography
                variant="h4"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginBottom: 0,
                }}
              >
                {txHash && !isBridgeLoading ? (
                  <>
                    <Typography
                      className={classes.waitModalText}
                      sx={{
                        fontSize: isSmallDevice
                          ? "1.0rem !important"
                          : "1.7rem !important",
                      }}
                    >
                      Swapped USDC from your side
                    </Typography>{" "}
                    <Typography
                      variant="h6"
                      className={classes.bridgingTxSuccessNote}
                      style={{
                        color: "var(--clr-purple-lt)",
                        fontSize: isSmallDevice
                          ? "1.0rem !important"
                          : "1.7rem !important",
                      }}
                    >
                      {`Note: Tx on receiver side may take ${
                        [fromChain, toChain].includes("polygon")
                          ? "20"
                          : [EVM_SUPPORTED_CHAINS, "tron"].includes(
                              fromChain
                            ) ||
                            [EVM_SUPPORTED_CHAINS, "tron"].includes(toChain)
                          ? "3-5"
                          : "few"
                      } minutes to confirm and to show up on explorer and to update receiver's account balance. Also please check txns table below for txn status on both ends.`}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h4" component="h5" className={classes.waitModalText}>
                    TRANSACTION WAS NOT CONFIRMED
                  </Typography>
                )}
              </Typography>
              {txHash && !bridgeError && (
                <div style={{ marginTop: 10, marginBottom: 0 }}>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href={getTxUrlByChain(fromChain, "mainnet", txHash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppButton
                      className={cx(
                        classes.bridgeButton,
                        isSmallDevice ? classes.bridgeButtonSM : false
                      )}
                    >
                      See tx details
                    </AppButton>
                  </a>
                </div>
              )}
            </div>

            {!txHash ? (
              <AppButton
                className={cx(
                  classes.bridgeButton,
                  isSmallDevice ? classes.bridgeButtonSM : false
                )}
              >
                Close
              </AppButton>
            ) : null}
          </Typography>
          <br />
        </>
      )}
    </DialogWrapper>
  );
};

export const NativeTokenBridgingDialog = ({
  isDialogOpen,
  isBridgeLoading,
  bridgeError,
  closeDialogHandler,
  fromChain,
  toChain,
  txHash,
}) => {
  const { classes, cx } = useStyles();
  const { getTxUrlByChain } = Others();
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);
  const fromChainIcon = require(`../../assets/icons/${fromChain}_icon.png`);
  const toChainIcon = require(`../../assets/icons/${toChain}_icon.png`);

  return (
    <DialogWrapper
      isDialogOpen={isDialogOpen}
      closeDialogHandler={closeDialogHandler}
    >
      {
        <>
          <br />
          <div className="flex-center-row">
            <img
              src={fromChainIcon}
              alt={fromChain}
              className={classes.imgLogo}
            />
            {txHash && !isBridgeLoading ? (
              <p className={classes.txStatusAnim}>
                <BridgingCompleted
                  height={isSmallDevice ? "48px" : "250px"}
                  width={isSmallDevice ? "100px" : "250px"}
                  style={{
                    height: isSmallDevice ? "48px" : "250px",
                    width: isSmallDevice ? "100px" : "250px",
                  }}
                />
              </p>
            ) : bridgeError ? (
              <p className={classes.txStatusAnim}>
                <BridgingProgress
                  height={isSmallDevice ? "48px" : "250px"}
                  width={isSmallDevice ? "100px" : "250px"}
                  style={{
                    height: isSmallDevice ? "48px" : "250px",
                    width: isSmallDevice ? "100px" : "250px",
                  }}
                />
              </p>
            ) : (
              <p className={classes.txStatusAnim}>
                <BridgingProgress
                  height={isSmallDevice ? "48px" : "250px"}
                  width={isSmallDevice ? "100px" : "250px"}
                  style={{
                    height: isSmallDevice ? "48px" : "250px",
                    width: isSmallDevice ? "100px" : "250px",
                  }}
                />
              </p>
            )}
            <img src={toChainIcon} alt={toChain} className={classes.imgLogo} />
          </div>

          {isBridgeLoading && !bridgeError && (
            <p
              className={classes.txStatus}
              style={{
                color: "rgb(197 161 37)",
                fontSize: isSmallDevice
                  ? "1.0rem !important"
                  : "1.9rem !important",
              }}
            >
              PENDING
            </p>
          )}
          {txHash && !isBridgeLoading && (
            <p
              className={classes.txStatus}
              style={{
                color: "#1f8b57",
                fontSize: isSmallDevice
                  ? "12px !important"
                  : "1.9rem !important",
              }}
            >
              Deposit Completed
            </p>
          )}
          {bridgeError && (
            <p
              className={classes.txStatus}
              style={{
                color: "red",
                fontSize: isSmallDevice
                  ? "1.0rem !important"
                  : "1.9rem !important",
              }}
            >
              FAILED
            </p>
          )}
          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: isSmallDevice
                ? "0.9rem !important"
                : "1.2rem !important",
            }}
          >
            {!bridgeError && txHash ? (
              <>
                The deposit transaction into the bridge has been completed. The
                release of tokens into the destination may take some time
                depending on destination chain. Please check the transaction
                table below for updates.
              </>
            ) : bridgeError ? (
              <>
                We were unable to process your transaction. Please try again. If
                this issue persists, please contact us at
                support@glitter.finance.
              </>
            ) : (
              ""
            )}
          </Typography>
          {txHash && !isBridgeLoading && (
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <a
                style={{
                  textDecoration: "none",
                }}
                href={getTxUrlByChain(fromChain, "mainnet", txHash)}
                target="_blank"
                rel="noreferrer"
              >
                <AppButton
                  className={cx(
                    classes.bridgeButton,
                    isSmallDevice ? classes.bridgeButtonSM : false
                  )}
                >
                  See tx details
                </AppButton>
              </a>
            </div>
          )}
          <Typography
            variant="body1"
            className={classes.technicalIssueline}
            sx={{ fontSize: isSmallDevice ? "0.7rem !important" : false }}
          >
            <br />
            In case of technical issues please contact us at{" "}
            <a
              href="mailto: support@glitter.finance"
              className={classes.linkTeam}
            >
              Glitter team.
            </a>
            <br />
          </Typography>
          <br />
        </>
      }
    </DialogWrapper>
  );
};

export const DisconnectWalletDialog = ({
  text,
  handleDisconnect,
  isDialogOpen,
  closeDialogHandler,
}) => {
  const { classes, cx } = useStyles();
  return (
    <DialogWrapper isDialogOpen={isDialogOpen}>
      <Box sx={{ textAlign: "center", padding: "24px 24px" }}>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", fontSize: "1.2rem" }}
        >
          {text}
        </Typography>
        <div className={css.inlineFlux} style={{ marginTop: "5%" }}>
          <AppButton
            className={classes.bridgeButton}
            style={{
              padding: "5% 100% 0% 100%",
            }}
            marginRight="140%"
            onClick={() => {
              handleDisconnect();
            }}
          >
            Yes
          </AppButton>
          <AppButton
            className={cx(classes.bridgeButton, classes.bridgeButtonRtBtn)}
            style={{
              padding: "5% 100% 0% 100%",
            }}
            onClick={() => {
              closeDialogHandler();
            }}
          >
            No
          </AppButton>
        </div>
      </Box>
    </DialogWrapper>
  );
};

export const ErrorRedirectDialog = () => {
  const { classes, cx } = useStyles();
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);

  const closeDialogHandler = () => {
    window.location.reload();
  };
  return (
    <>
      <DialogWrapper isDialogOpen={true}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: "1.0rem !important",
              marginTop: "5px  !important",
              marginBottom: "10px !important",
              padding: "10px 1px",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                paddingBottom: "10px",
              }}
            >
              Error Experienced
            </span>
            <br />
            <br />
            <span>
              We're sorry, but we've encountered an error serving your request.
              Please refresh the page or try again later.
            </span>
            <br />
            <br />
            <span style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              If this error persists, please reach out to us at &nbsp;
              <a
                href="mailto: support@glitter.finance"
                className={classes.linkTeam}
              >
                support@glitter.finance
              </a>
              .
            </span>
          </Typography>
          <div className={css.inlineFlux}>
            <AppButton
              className={cx(
                classes.bridgeButton,
                isSmallDevice ? classes.bridgeButtonSM : false
              )}
              onClick={closeDialogHandler}
            >
              Try Again
            </AppButton>
          </div>
        </Box>
      </DialogWrapper>
    </>
  );
};

export const TermsAndConditionsDialog = () => {
  const { classes, cx } = useStyles();
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);

  const [open, setOpen] = useState(true);

  return (
    <>
      <DialogWrapper isDialogOpen={open}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: "1.0rem !important",
              marginTop: "5px  !important",
              marginBottom: "10px !important",
              padding: "10px 1px",
            }}
          >
            By using this interface, you agree to the{" "}
            <a
              href="https://www.glitterfinance.org/terms-and-conditions"
              target="_blank" rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </Typography>
          <div className={css.inlineFlux}>
            <AppButton
              className={cx(
                classes.bridgeButton,
                isSmallDevice ? classes.bridgeButtonSM : false
              )}
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </AppButton>
          </div>
        </Box>
      </DialogWrapper>
    </>
  );
};

export const SessionEndingDialog = ({
  text,
  isDialogOpen,
  closeDialogHandler,
  continueHandler,
  disconnectHandler,
}) => {
  const { classes, cx } = useStyles();
  const screenSize = useScreenSize().getSize();
  const isSmallDevice = ["sm", "xs"].includes(screenSize);

  return (
    <DialogWrapper
      isDialogOpen={isDialogOpen}
      closeDialogHandler={closeDialogHandler}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          className={classes.waitModalText}
          sx={{ fontSize: "1.0rem !important", marginTop: "0px" }}
        >
          <span
            style={{
              fontSize: "1.5rem",
              paddingBottom: "10px",
            }}
          >
            Session Timeout:
          </span>

          <Typography
            variant="h4"
            className={classes.waitModalText}
            sx={{
              fontSize: "1.0rem !important",
              marginTop: "5px  !important",
              marginBottom: "10px !important",
              padding: "10px 1px",
            }}
          >
            <br />
            <span>You have been connected for more than 15:00 minutes.</span>
            <br />
            <span>Do you still wish to continue your session?</span>
            <br />
            <br />
            <span style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              Message will auto close and disconnect all wallets after 5:00 more
              minutes.
            </span>
          </Typography>
        </Typography>
        <div className={css.inlineFlux}>
          <AppButton
            className={cx(
              classes.bridgeButton,
              isSmallDevice ? classes.bridgeButtonSM : false
            )}
            onClick={continueHandler}
          >
            Continue
          </AppButton>
          <AppButton
            className={cx(
              classes.bridgeButton,
              classes.bridgeButtonRtBtn,
              isSmallDevice ? classes.bridgeButtonSM : false
            )}
            onClick={disconnectHandler}
          >
            Disconnect
          </AppButton>
        </div>
      </Box>
    </DialogWrapper>
  );
};
