export const capitalize = (input) => {
  if (
    input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
  ) {
    var words = input?.split("_");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0]?.toUpperCase() + element?.slice(1, element?.length)
      );
    });
    return CapitalizedWords?.join(" ");
  }
  input = "";
  return input;
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  const time = formatAMPM(d);
  return `${day}/${month}/${year} ${time}`;
};

export const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const truncateString = (str, base = 6) => {
  if (str.length > 16) {
    return `${str.substr(0, base)}...${str.substr(
      str.length - base,
      str.length
    )}`;
  }
  return str;
};

export const toLowerCase = (str) => {
  return str?.toString()?.toLowerCase();
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const rmvWltsLS = () => {
  const PeraWallet = localStorage.getItem("PeraWallet.Wallet");
  if (PeraWallet) {
    localStorage.removeItem("PeraWallet.Wallet");
  }
  const localStorageSession = localStorage.getItem("walletconnect");
  if (localStorageSession) {
    localStorage.removeItem("walletconnect");
  }
  const pera_walletconnect = localStorage.getItem("pera_walletconnect");
  if (pera_walletconnect) {
    localStorage.removeItem("pera_walletconnect");
  }
  const DeflyWalletWallet = localStorage.getItem("DeflyWallet.Wallet");
  if (DeflyWalletWallet) {
    localStorage.removeItem("DeflyWallet.Wallet");
  }
  const defly_walletconnect = localStorage.getItem("defly_walletconnect");
  if (defly_walletconnect) {
    localStorage.removeItem("defly_walletconnect");
  }
};
export const isConctdSession = () => {
  let flag = true,
    isAnyInLs = null;
  isAnyInLs = localStorage.getItem("PeraWallet.Wallet");
  if (isAnyInLs) {
    return flag;
  }
  isAnyInLs = localStorage.getItem("pera_walletconnect");
  if (isAnyInLs) {
    return flag;
  }
  isAnyInLs = localStorage.getItem("DeflyWallet.Wallet");
  if (isAnyInLs) {
    return flag;
  }
  isAnyInLs = localStorage.getItem("defly_walletconnect");
  if (isAnyInLs) {
    return flag;
  }

  flag = false;
  return flag;
};
