import { ChainNames, Chains } from "@glitter-finance/sdk-web";
import {
  algorandTokenIds,
  arbitrumTokenIds,
  avalancheTokenIds,
  bnbTokenIds,
  ethereumTokenIds,
  optimismTokenIds,
  polygonTokenIds,
  solanaTokenIds,
  tronTokenIds,
  zkEvmTokenIds,
} from "./tokens";
import { capitalize, toLowerCase } from "./utils";
export const defaultPageSize = 10;
export const baseUrl = "https://api-v2.glitterfinance.org/api";
export const DEFAULT_NETWORK = "mainnet";

export const ALGORAND_ACC_ENDPOINT = () => {
  const url =
    DEFAULT_NETWORK === "testnet"
      ? "https://testnet-api.algonode.cloud"
      : "https://mainnet-api.algonode.cloud";

  return `${url}/v2/accounts`;
};

export const SOLANA_RPC = () => {
  const LCL = "https://glitter-main-e9a4.mainnet.rpcpool.com";
  const KEY = "f1fd97fd-5fcc-426f-afd2-0877ab101099";
  // const MAINNET_PROD = "https://glitter-main-e9a4.mainnet.rpcpool.com";
  const TESTNET = "https://api.testnet.solana.com";
  const LOCAL_MAINNET_TEST = `${LCL}/${KEY}`;
  const curPath = window.location.href;
  const isTestnet = DEFAULT_NETWORK === "testnet";
  const isLocalhost = curPath.indexOf("localhost") > 0;
  if (!isTestnet && isLocalhost) {
    return LOCAL_MAINNET_TEST;
  }
  return isTestnet ? TESTNET : LOCAL_MAINNET_TEST;
};

export const algorandConfig = {
  testnet: [
    {
      id: "usdc",
      decimal: 6,
      minBal: 1,
      assetId: algorandTokenIds.test.usdc,
    },
  ],
  mainnet: [
    {
      id: "usdc",
      decimal: 6,
      minBal: 1,

      assetId: algorandTokenIds.main.usdc,
    },
  ],
};

export const solanaConfig = {
  testnet: [
    {
      id: "usdc",
      decimal: 6,
      minBal: 1,

      mint: solanaTokenIds.test.usdc,
    },
  ],
  mainnet: [
    {
      id: "usdc",
      decimal: 6,
      minBal: 1,

      mint: solanaTokenIds.main.usdc,
    },
  ],
};

export const chains = [
  {
    id: ChainNames.ETHEREUM,
    name: capitalize(ChainNames.ETHEREUM.toString()),
    icon: "ethereum_icon",
    key: Chains.ETHEREUM,
  },
  // {
  //   id: ChainNames.BSC,
  //   name: "BNB Chain",
  //   icon: "binance_icon",
  //   key: Chains.BSC,
  // },
  {
    id: ChainNames.AVALANCHE,
    name: "Avalanche C-chain",
    icon: "avalanche_icon",
    key: Chains.AVALANCHE,
  },
  // {
  //   id: ChainNames.ARBITRUM,
  //   name: "Arbitrum One",
  //   icon: "arbitrum_icon",
  //   key: Chains.ARBITRUM,
  // },
  {
    id: ChainNames.POLYGON,
    name: "Polygon PoS",
    icon: "polygon_icon",
    key: Chains.POLYGON,
  },
  // {
  //   id: ChainNames.OPTIMISM,
  //   name: "OP Mainnet",
  //   icon: "optimism_icon",
  //   key: Chains.OPTIMISM,
  // },
  {
    id: ChainNames.ALGORAND,
    name: ChainNames.ALGORAND,
    icon: "algorand_icon",
    key: Chains.ALGO,
  },
  {
    id: ChainNames.SOLANA,
    name: ChainNames.SOLANA,
    icon: "solana_icon",
    key: Chains.SOLANA,
  },
  {
    id: ChainNames.TRON,
    name: capitalize(ChainNames.TRON.toString()),
    icon: "tron_icon",
    key: Chains.TRON,
  },
  // {
  //   id: ChainNames.ZKEVM,
  //   name: capitalize(ChainNames.ZKEVM.toString()),
  //   icon: "zkevm_icon",
  //   key: Chains.ZKEVM,
  // },
];

export const getAllTokensByChainIdAndNetworkId = (chainId, networkId) => {
  const tokensMap = {
    algorand: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? algorandTokenIds.main.usdc
            : algorandTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
    ],
    solana: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? solanaTokenIds.main.usdc
            : solanaTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
    ],
    ethereum: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? ethereumTokenIds.main.usdc
            : ethereumTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
      {
        id: "euroc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? ethereumTokenIds.main.euroc
            : ethereumTokenIds.test.euroc,
        value: 1.07,
        icon: "euroc_icon",
        type: "token",
        name: "EUROC",
        decimals: 6,
      },
    ],
    avalanche: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? avalancheTokenIds.main.usdc
            : avalancheTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
      {
        id: "euroc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? avalancheTokenIds.main.euroc
            : avalancheTokenIds.test.euroc,
        value: 1.07,
        icon: "euroc_icon",
        type: "token",
        name: "EUROC",
        decimals: 6,
      },
    ],
    polygon: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? polygonTokenIds.main.usdc
            : polygonTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
    ],
    tron: [
      {
        id: "usdc",
        asset_id:
          networkId === DEFAULT_NETWORK
            ? tronTokenIds.main.usdc
            : tronTokenIds.test.usdc,
        name: "USDC",
        value: 0.95,
        icon: "usdc_icon",
        type: "token",
        decimals: 6,
      },
    ],
    arbitrum: [
      
    ],
    zkevm: [
      
    ],
    optimism: [
      
    ],
    binance: [
      
    ],
  };

  return tokensMap[chainId.toLowerCase()];
};

export const tokens = [
  {
    id: "usdc",
    asset_id: "10458941",
    name: "USDC",
    value: 0.95,
    icon: "usdc_icon",
    type: "token",
    decimals: 6,
  },
  {
    id: "euroc",
    asset_id: "",
    name: "EUROC",
    icon: "euroc_icon",
    type: "token",
    value: 1.07,
    decimals: 6,
  },
];

export const tokenRoutes = [
  {
    token: "usdc",
    initChains: [
      ChainNames.ALGORAND,
      ChainNames.SOLANA,
      ChainNames.ETHEREUM,
      ChainNames.AVALANCHE,
      ChainNames.POLYGON,
      ChainNames.TRON,
    ],
    bridgeRoutes: {
      usdc: [
        ChainNames.ALGORAND,
        ChainNames.SOLANA,
        ChainNames.ETHEREUM,
        ChainNames.AVALANCHE,
        ChainNames.POLYGON,
        ChainNames.TRON,
      ],
    },
  },
  {
    token: "euroc",
    initChains: [
      ChainNames.ARBITRUM,
      ChainNames.ZKEVM,
      ChainNames.BSC,
      ChainNames.OPTIMISM,
      ChainNames.POLYGON,
      ChainNames.AVALANCHE,
      ChainNames.ETHEREUM,
    ],
    bridgeRoutes: {
      euroc: [
        ChainNames.ARBITRUM,
        ChainNames.ZKEVM,
        ChainNames.BSC,
        ChainNames.OPTIMISM,
        ChainNames.POLYGON,
        ChainNames.AVALANCHE,
        ChainNames.ETHEREUM,
      ],
    },
  },
];

export const walletConfig = [
  {
    chain: ChainNames.ALGORAND,
    tokens: ["algo", "xsol", "usdc"],
    wallets: ["defly", "pera"],
  },
  {
    chain: ChainNames.SOLANA,
    tokens: ["sol", "xalgo", "usdc"],
    wallets: ["phantom", "solflare"],
  },
  {
    chain: ChainNames.ETHEREUM,
    tokens: ["usdc"],
    wallets: ["metamask", "coin98", "core", "walletConnect"],
  },
  {
    chain: ChainNames.AVALANCHE,
    tokens: ["usdc",],
    wallets: ["metamask", "coin98", "core", "walletConnect"],
  },
  {
    chain: ChainNames.POLYGON,
    tokens: ["usdc",],
    wallets: ["metamask", "coin98", "walletConnect"],
  },
  {
    chain: ChainNames.TRON,
    tokens: ["usdc"],
    wallets: ["tronlink"],
  },
  {
    chain: ChainNames.ARBITRUM,
    tokens: [],
    wallets: ["metamask", "coin98", "walletConnect"],
  },
  {
    chain: ChainNames.ZKEVM,
    tokens: [],
    wallets: ["metamask", "coin98", "walletConnect"],
  },
  {
    chain: ChainNames.OPTIMISM,
    tokens: [],
    wallets: ["metamask", "coin98", "walletConnect"],
  },
  {
    chain: ChainNames.BSC,
    tokens: [],
    wallets: ["metamask", "coin98", "walletConnect"],
  },
];

export const wallets = [
  { id: "phantom", name: "Phantom", icon: "phantom_icon" },
  { id: "solflare", name: "Solflare", icon: "solflare_icon" },
  { id: "metamask", name: "Metamask", icon: "metamask_icon" },
  { id: "core", name: "Core", icon: "core_icon" },
  { id: "coin98", name: "Coin98", icon: "coin98_icon" },
  { id: "defly", name: "Defly", icon: "defly_icon" },
  { id: "pera", name: "Pera", icon: "pera_icon" },
  { id: "tronlink", name: "TronLink", icon: "tronlink_icon" },
  { id: "exodus", name: "Exodus", icon: "exodus_icon" },
  { id: "walletConnect", name: "WC", icon: "walletConnect_icon" },
];

export const steps = {
  SRC_CHAIN: "SRC_CHAIN",
  SRC_TOKEN: "SRC_TOKEN",
  DEST_CHAIN: "DEST_CHAIN",
  DEST_TOKEN: "DEST_TOKEN",
  AMT_SWAP: "AMT_SWAP",
};

export const constants = {
  feeRate: 0.005,
  solMinBalance: 0.05,
  algoMinBalance: 5,
  pollingInterval: 4000,
  pollingRetry: 6,
  maxRetries: 30,
};

export const EVM_SUPPORTED_CHAINS = [
  toLowerCase(ChainNames.ETHEREUM),
  toLowerCase(ChainNames.AVALANCHE),
  toLowerCase(ChainNames.POLYGON),
  toLowerCase(ChainNames.BSC),
  toLowerCase(ChainNames.ARBITRUM),
  toLowerCase(ChainNames.ZKEVM),
  toLowerCase(ChainNames.OPTIMISM),
];

export const txnHistoryColumns = [
  { key: "status", title: "Status", align: "left" },
  { key: "time", title: "Time", align: "left" },
  { key: "network", title: "Network", align: "left" },
  { key: "address", title: "Address", align: "left" },
  { key: "token", title: "Token", align: "left" },
  { key: "amount", title: "Amount", align: "right" },
  { key: "txnLinks", title: "TXN Links", align: "left" },
];

export const footerData = [
  {
    id: "discord",
    icon: "discord_icon",
    url: "https://discord.com/invite/QpRtHbnuEU",
  },
  {
    id: "github",
    icon: "github_icon",
    url: "https://github.com/Glitter-Finance",
  },
  {
    id: "linkedin",
    icon: "linkedin_icon",
    url: "https://www.linkedin.com/company/glitter-finance/",
  },
  {
    id: "instagram",
    icon: "instagram_icon",
    url: "https://www.instagram.com/glitter.finance/",
  },
  {
    id: "telegram",
    icon: "telegram_icon",
    url: "https://t.me/GlitterFinanceGlobal",
  },
  {
    id: "twitter",
    icon: "twitter_icon",
    url: "https://twitter.com/GlitterFinance",
  },
  {
    id: "youtube",
    icon: "youtube_icon",
    url: "https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA",
  },
];

export function getBridgeFee(token) {
  return 0.005;
}
