import React from "react";
import classNames from "classnames";

import css from "./css/AppButton.module.css";

const AppButton = (props) => {
  const { children, className, isDisabled, onClick, style, marginRight } = props;

  const classStr = classNames(
    isDisabled ? css.disabledButton : css.button,
    className
  );

  return (
    <div className={css.buttonContainer} style={{ marginRight: marginRight }}>
      <button
        tyle="button"
        style={{...style}}
        className={classStr}
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </button>
    </div>
  );
};

export default AppButton;
