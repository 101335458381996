import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    color: "#5e14a8",
    borderRadius: "12px",
    textTransform: "uppercase",
    background: theme.palette.sitePalette.linerGradientBg,
  },
  dialogPaperDarkUSDC: {
    background: theme.palette.sitePalette.linerGradientBg,
    border: "1px solid #91847F",
    borderRadius: "23px",
    backdropFilter: "blur(21px)",
    color: "#5e14a8",
  },
  dialogPaperDarkUSDCLoading: {
    background: theme.palette.sitePalette.linerGradientBg,
    border: "1px solid #91847F",
    borderRadius: "23px",
    backdropFilter: "blur(21px)",
    color: "#5e14a8",
    width: "100%",
  },
  waitModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginBottom: "30px",
  },
  waitModalText: {
    fontFamily: "ProximaNova",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#5e14a8",
    marginTop: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
    },
  },
  closeIcon: {
    fontSize: "125px",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      height: "100px",
    },
  },
  imgLogo: {
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "30px",
    },
  },
  swapIcon: {
    color: "#fff",
    fontSize: "125px",
    [theme.breakpoints.down("xs")]: {
      height: "100px",
      fontSize: "100px",
    },
  },
  bridgingTxSuccessNote: {
    fontFamily: "ProximaNova",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "18px !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
    },
  },
  dialogTitleRoot: {
    color: "#5e14a8",
    textAlign: "center",
  },
  technicalIssueline: {
    color: "#5e14a8",
    textAlign: "center",
    wordBreak: "break-word",
  },
  txStatus: {
    fontSize: "1.9rem",
    textAlign: "center",
    fontWeight: "bold",
    margin: "0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem !important",
    },
  },
  txStatusAnim: {
    margin: "0px",
    width: "300px",
    textAlign: "center",
  },
  bridgeButton: {
    fontSize: "12px !important",
    padding: "1px 30px",
    margin: "0px 10px",
    background:
      "linear-gradient( 170.98deg, rgba(50, 112, 205, 0.45) -12.36%, rgba(168, 66, 249, 0.5) 100.49% ), #ffffff",
    border: "2px solid rgba(255, 255, 255, 0.9)",
    backdropFilter: "blur(9px)",
    borderRadius: "47px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "1px 15px !important",
      margin: "0px 5px !important",
    },
  },
  bridgeButtonRtBtn: {
    background:
      "linear-gradient( 170.98deg, rgba(50, 112, 205, 0.45) -12.36%, rgb(168 66 249 / 93%) 100.49% ),#f3f3f3 !important",
  },
  bridgeButtonSM: {
    fontSize: "8px !important",
    padding: "1px 15px !important",
    margin: "0px 5px !important",
  },
  linkTeam: {
    textDecoration: "none",
  },
}));

export default useStyles;
