import React, { createContext, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";

const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState("dark");

  const setThemeMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const getThemeMode = useCallback(() => {
    if (mode) {
      return mode;
    }
    return "dark";
  }, [mode]);

  const value = useMemo(
    () => ({
      setThemeMode,
      getThemeMode,
    }),
    [getThemeMode]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContext;
