import { createSlice, current } from "@reduxjs/toolkit";

const initialData = {
  isSwapping: false,

  recentSwaps: [],
};

const statusSlice = createSlice({
  name: "bridgeStatus",
  initialState: initialData,
  reducers: {
    setSwapStatus: (state, action) => {
      const data = action.payload;
      return { ...current(state), isSwapping: data };
    },
    addToRecentSwaps: (state, action) => {
      const data = action.payload;
      const currentState = { ...current(state) };
      let newSets = [];
      if (localStorage.getItem("recentSwaps") !== null) {
        const oldSwaps = JSON.parse(localStorage.getItem("recentSwaps"));
        newSets = [...oldSwaps];
      }
      // Check by tx id
      const alreadyExistsSwap = newSets.find((x) => x.id === data.id);
      if (!alreadyExistsSwap) {
        //check by same chain. If same chain swap/bridge exits, remove it and add new one
        const findIndex = newSets.findIndex(
          (x) =>
            x?.destinationChain?.value?.id ===
              data?.destinationChain?.value?.id &&
            x?.sourceChain?.value?.id === data?.sourceChain?.value?.id
        );
        if (findIndex !== -1) {
          newSets.splice(findIndex, 1);
          newSets.push(data);
        } else {
          newSets.push(data);
        }
        localStorage.setItem("recentSwaps", JSON.stringify(newSets));
      }
      return { ...currentState, recentSwaps: newSets };
    },
    emptyRecentSwaps: (state, action) => {
      const currentState = { ...current(state) };
      return { ...currentState, recentSwaps: [] };
    },
    setToNoSwap: (state) => {
      const currentState = { ...current(state) };
      return { ...currentState, isSwapping: false };
    },
  },
});

export const statusActions = statusSlice.actions;

export default statusSlice;
