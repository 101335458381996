import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { NotificationContainer } from "react-notifications";
import { Route, Routes } from "react-router-dom";
import { theme } from "../utils/theme";

import { TermsAndConditionsDialog } from "../components/common/StatusNotifiers";
import "./App.css";

const Home = lazy(() => import("../pages/Home/Home"));
const Widget = lazy(() => import("../pages/Widget/Widget"));
const BlockedPage = lazy(() => import("../pages/BlockedPage/BlockedPage"));

let darkTheme = createTheme(theme);
const _darkTheme = responsiveFontSizes(darkTheme);
const isWidget = process.env.REACT_APP_IS_WIDGET === "true";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={_darkTheme}>
        <TermsAndConditionsDialog />
        <Routes>
          <Route path="/" element={isWidget ? <Widget /> : <Home />} />
          <Route
            path="/blocked-page"
            element={
              <BlockedPage
                heading="Unavailable In Your Country"
                text="Due to regulatory uncertainty in the US, Glitter Finance bridging services are unavailable in your country."
              />
            }
          />
          <Route
            path="*"
            element={
              <BlockedPage
                heading="Page Not Found"
                text="We're sorry, but the page you requested could not be served.  Please check the url to make sure that the page you're looking for is spelled correctly."
              />
            }
          />
        </Routes>
        <NotificationContainer />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
