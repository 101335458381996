const algorandTokenIds = {
  test: {
    algo: "",
    xsol: "97628726",
    usdc: "10458941",
    xgli: "",
  },
  main: {
    algo: "",
    xsol: "792313023",
    usdc: "31566704",
    xgli: "607591690",
  },
};

const solanaTokenIds = {
  test: {
    sol: "11111111111111111111111111111111",
    xalgo: "xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms",
    usdc: "CpMah17kQEL2wqyMKt3mZBdTnZbkbfx4nqmQMFDP5vwp",
    xgli: "",
  },
  main: {
    sol: "11111111111111111111111111111111",
    xalgo: "xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms",
    usdc: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    xgli: "FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg",
  },
};

const ethereumTokenIds = {
  test: {
    eth: "0xc918b9719A0e04Df45842eec88FC84480266b568",
    usdc: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
    euroc: "", // TODO Update this later on
    nosec: "",
  },
  main: {
    eth: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    euroc: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
    nosec: "0x68f0C0003F1826c4E9646Df7E1ecf3707FeE0581",
  },
};

const avalancheTokenIds = {
  test: {
    avax: "0x07c48413bEA695Ef37a75Be8A09ec84A4c8a6bc1",
    usdc: "0x5425890298aed601595a70AB815c96711a31Bc65",
    euroc: "",
    xnosec: "",
  },
  main: {
    avax: "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
    usdc: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    euroc: "0xc891eb4cbdeff6e073e859e987815ed1505c2acd",
    xnosec: "0xa234acbd98a917f6dda69298e0e7290380006cf1",
  },
};

const polygonTokenIds = {
  test: {
    matic: "",
    usdc: "0x0fa8781a83e46826621b3bc094ea2a0212e71b23",
    xnosec: "",
  },
  main: {
    matic: "",
    usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    xnosec: "0x72decebe0597740551396d3c9e7546cfc97971e9",
  },
};

const tronTokenIds = {
  test: {
    trx: "",
    usdc: "TFGBSrddCjLJAwuryZ9DUxtEmKv13BPjnh",
  },
  main: {
    trx: "",
    usdc: "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
  },
};

const arbitrumTokenIds = {
  test: {},
  main: {
    xnosec: "0x446c264ed8888dad27f5452094d2ceadb1e038ea",
  },
};

const zkEvmTokenIds = {
  test: {},
  main: {
    xnosec: "0x175355fa6fa82f1bb6868cd885da13069c4e861c",
  },
};

const bnbTokenIds = {
  test: {},
  main: {
    xnosec: "0x446c264ed8888dad27f5452094d2ceadb1e038ea",
  },
};

const optimismTokenIds = {
  test: {},
  main: {
    xnosec: "0x446c264ed8888dad27f5452094d2ceadb1e038ea",
  },
};

export {
  algorandTokenIds,
  arbitrumTokenIds,
  avalancheTokenIds,
  bnbTokenIds,
  ethereumTokenIds,
  optimismTokenIds,
  polygonTokenIds,
  solanaTokenIds,
  tronTokenIds,
  zkEvmTokenIds,
};
