import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  minAmountMsg: "",
  walletAlert: {
    isOpen: false,
    message: "",
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    setMinAmountMsg: (state, { payload }) => {
      return {
        ...state,
        minAmountMsg: payload,
      };
    },
    setWalletAlert: (state, { payload }) => {
      return {
        ...state,
        walletAlert: {
          isOpen: payload.isOpen,
          message: payload.message,
        },
      };
    },
    resetCommenAlerts: () => {
      return {
        ...initialData,
      };
    },
  },
});

export const commonActions = commonSlice.actions;

export default commonSlice;
