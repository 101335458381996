import { createSlice, current } from "@reduxjs/toolkit";

const initialData = {
  source: {
    chain: null,
    id: null,
    address: null,
    walletBalance: {},
    minApiBal: 0,
  },
  txStatusByAllResources: "",
  isSrcWltStpPsd: false,
  destination: { chain: null, id: null, address: null },
  providers: [],
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialData,
  reducers: {
    setSourceWallet: (state, action) => {
      const data = action.payload;
      return { ...current(state), source: { ...data } };
    },
    setDestinationWallet: (state, action) => {
      const data = action.payload;
      return { ...current(state), destination: { ...data } };
    },
    setIsSrcWltStpPsd: (state, action) => {
      const data = action.payload;
      return { ...current(state), isSrcWltStpPsd: data };
    },
    setTxStatusByAllResources: (state, action) => {
      const data = action.payload;
      return { ...current(state), txStatusByAllResources: data };
    },
    setProvider: (state, action) => {
      const { id, provider } = action.payload;
      const currentData = current(state);
      const existingProviders = currentData.providers;
      const exists = existingProviders.findIndex((i) => i.id === id);
      if (exists > 0) {
        existingProviders[exists] = { id, provider };
        return { ...currentData, providers: existingProviders };
      }
      const newSet = [...existingProviders, { id, provider }];
      return { ...currentData, providers: newSet };
    },
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice;
