import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";

const useScreenSize = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));

  const getSize = () => {
    if (lg) {
      return "lg";
    }
    if (md) {
      return "md";
    }
    if (sm) {
      return "sm";
    }
    if (xs) {
      return "xs";
    }
    if (xl) {
      return "xl";
    }
  };

  return { getSize };
};

export default useScreenSize;
