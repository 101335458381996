import { createSlice, current } from "@reduxjs/toolkit";

const defaultVal = { value: null, status: null };
const initialData = {
  id: null,
  sourceChain: defaultVal,
  sourceToken: defaultVal,
  sourceWallet: null,
  destinationChain: defaultVal,
  destinationToken: defaultVal,
  destinationWallet: null,
  swapAmount: null,
  isCompleted: false,
  bridgeConfirmation: false,
  onExchange: false,
};

const bridgeSlice = createSlice({
  name: "bridge",
  initialState: initialData,
  reducers: {
    setSourceChain: (state, action) => {
      const data = action.payload;
      return { ...current(state), sourceChain: data };
    },
    setSourceWallet: (state, action) => {
      const data = action.payload;
      return { ...current(state), sourceWallet: data };
    },
    setSourceToken: (state, action) => {
      const data = action.payload;
      return { ...current(state), sourceToken: data };
    },
    setDestinationChain: (state, action) => {
      const data = action.payload;
      return { ...current(state), destinationChain: data };
    },
    setDestinationWallet: (state, action) => {
      const data = action.payload;
      return { ...current(state), destinationWallet: data };
    },
    setDestinationToken: (state, action) => {
      const data = action.payload;
      return { ...current(state), destinationToken: data };
    },
    finalizeSwap: (state, action) => {
      const { txnHash, amount } = action.payload;
      return {
        ...current(state),
        swapAmount: amount,
        id: txnHash,
        isCompleted: true,
        onExchange: false,
      };
    },
    editSourceChain: (state) => {
      const { id, sourceChain } = current(state);
      // reset the state , just only keep the id and sourceChain of specific step
      return { ...initialData, id, sourceChain };
    },
    editSourceToken: (state) => {
      const { id, sourceChain, sourceToken, sourceWallet } = current(state);
      return { ...initialData, id, sourceChain, sourceToken, sourceWallet };
    },
    editDestinationChain: (state) => {
      const { destinationToken, destinationWallet, swapAmount } = initialData;
      const st = current(state);
      return { ...st, destinationToken, destinationWallet, swapAmount };
    },
    editDestinationToken: (state) => {
      const { swapAmount } = initialData;
      return { ...current(state), swapAmount };
    },
    setBridgeConfirmation: (state) => {
      return { ...current(state), bridgeConfirmation: true };
    },
    resetBridge: () => {
      return { ...initialData };
    },
    setBridgeOnExchange: (state, action) => {
      const newBridge = action.payload;
      return { ...current(state), ...newBridge, onExchange: true };
    },
    resetOnExchange: (state) => {
      return { ...current(state), onExchange: false };
    },
  },
});

export const bridgeActions = bridgeSlice.actions;

export default bridgeSlice;
